export default function header() {
	// Add mouse in and mouse out on .header .menu-item and also add class on header .hovering
	const headerMenuItems = document.querySelectorAll('.header__menu .menu-item');
	const header = document.querySelector('.header');

	headerMenuItems.forEach(function(menuItem) {
		menuItem.addEventListener('mouseover', function() {
			header.classList.add('hovering');
			this.classList.add('hovering');
		});

		menuItem.addEventListener('mouseout', function() {
			header.classList.remove('hovering');
			this.classList.remove('hovering');
			;
		});
	});
}