// import swiper and Pagination module
import Swiper from 'swiper';
// import  only swiper pagination styles
import 'swiper/swiper-bundle.css';
// eslint-disable-next-line
import 'swiper/css/navigation';

export default function news() {
	// eslint-disable-next-line no-unused-vars
	const gallerySlider = document.querySelector('.gallery-slider__slide');

	if(!gallerySlider) return;

	const swiper = new Swiper('.gallery-slider__slide', {
		spaceBetween: 16,
		grabCursor: true,
		breakpoints: {
			320: {
				initialSlide: 1,
				slidesPerView: 1.1,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 2.2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3.1,
				spaceBetween: 16,
			},
		},
	});
}
