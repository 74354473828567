export default function Solutions() {
 const solutionsGarageCard = document.querySelector('.solutions__card--fleet');
 const solutionsFleetCard = document.querySelector('.solutions__card--garage');
 const solutionsBlock = document.querySelector('.solutions');

 if(!solutionsGarageCard || !solutionsFleetCard) return;

 // WHen mouse is inising the card add class to the parent
 solutionsGarageCard.addEventListener('mouseenter', () => {
	solutionsBlock.classList.add('solutions-hover--fleet');
 });

 solutionsFleetCard.addEventListener('mouseenter', () => {
	solutionsBlock.classList.add('solutions-hover--garage');
 });

 // When mouse is leaving the card remove class from the parent
 solutionsGarageCard.addEventListener('mouseleave', () => {
	solutionsBlock.classList.remove('solutions-hover--fleet');
 });

 solutionsFleetCard.addEventListener('mouseleave', () => {
	solutionsBlock.classList.remove('solutions-hover--garage');
 });
}