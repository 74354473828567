// import swiper and Pagination module
import Swiper from 'swiper';
// Navigation
import { Navigation } from 'swiper/modules';
// import  only swiper pagination styles
import 'swiper/swiper-bundle.css';
// eslint-disable-next-line
import 'swiper/css/navigation';

export default function testimonials() {
	const testimonial = document.querySelector('.testimonial__slider');

	if(!testimonial) return;
	// eslint-disable-next-line no-unused-vars
	const swiper = new Swiper('.testimonial__slider', {
		slidesPerView: 'auto',
		spaceBetween: 20,
		centeredSlides: true,
		loop: true,
		modules: [Navigation],
		navigation: {
			nextEl: '.testimonials__next',
			prevEl: '.testimonials__prev',
		},
	});

	if (window.innerWidth < 640) {
		swiper.destroy();
	}
}
