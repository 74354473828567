// import swiper and Pagination module
import Swiper from 'swiper';

import { Navigation } from 'swiper/modules';
// import  only swiper pagination styles
import 'swiper/swiper-bundle.css';
// eslint-disable-next-line
import 'swiper/css/navigation';

export default function news() {
	// eslint-disable-next-line no-unused-vars
	const swiper = new Swiper('.news__list', {
		spaceBetween: 16,
		loop: true,
		grabCursor: true,
		navigation: {
			nextEl: '.news__next',
			prevEl: '.news__prev',
		},
		modules: [Navigation],
		breakpoints: {
			320: {
				slidesPerView: 1.1,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 2.2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
		},
	});
}
