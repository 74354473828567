/**
 * Global imports
 */
import svg4everybody from 'svg4everybody';
import MobileMenu from './components/mobile-menu';
import languageSwitcher from './components/lang-switcher';
import testimonials from './components/testimonials';
import Solutions from './components/solutions';
import contentWithCards from './components/content-with-card';
import news from './components/news';
import header from './components/header';
import pricing from './components/pricing';
import demoModal from './components/demoModal';
import gallerySlider from './gallerySlider';

document.addEventListener('DOMContentLoaded', () => {
	svg4everybody();
	MobileMenu();
	languageSwitcher();
	testimonials();
	Solutions();
	contentWithCards();
	news();
	header();
	pricing();
	demoModal();
	gallerySlider();
});
