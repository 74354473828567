import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function contentWithCards() {
	//Find all .content-with-cards .content-with-cards__wrap .half. THere are 2 halfs. Once I scroll in past it first half foes top and second half goes bottom

	const contentWithCards = document.querySelectorAll('.content-with-cards');
	const contentWithCardsHolders = document.querySelectorAll('.content-with-cards .half');

	if(!contentWithCards) return;

	// If document width is larger than 1024px, return
	if (window.innerWidth > 1024) {
		contentWithCardsHolders.forEach((contentWithCardsHolder) => {

			ScrollTrigger.create({
				trigger: contentWithCardsHolder,
				start: 'top bottom',
				end: 'bottom top',
				scrub: 3,
				onUpdate: (self) => {
					const velocity = self.getVelocity();
					//contentWithCardsHolder.style.setProperty('--animation-speed', velocity);
					console.log(velocity);
				},
			});
		});
	} else {
		contentWithCards.forEach((contentWithCard) => {
			const firstHalf = contentWithCard.querySelector('.half:nth-child(1)');
			const secondHalf = contentWithCard.querySelector('.half:nth-child(2)');

			gsap.fromTo(
				firstHalf,
				{
					x: '100%',
				},
				{
					scrollTrigger: {
						trigger: contentWithCard,
						start: 'top bottom',
						end: 'bottom top',
						scrub: 3,
						toggleActions: 'play none none reverse',
					},
					x: '-50%',
				}
			);

			gsap.fromTo(
				secondHalf,
				{
					x: '-100%',
				},
				{
					scrollTrigger: {
						trigger: contentWithCard,
						start: 'top bottom',
						end: 'bottom top',
						scrub: 3,
						toggleActions: 'play none none reverse',
					},
					x: '50%',
				}
			);
		});
	}
}
