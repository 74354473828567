export default function demoModal() {



	const modal = document.querySelector('.js-demo-modal');
	const modalClose = document.querySelector('.demo-modal__close');

	if(!modal) return;

	// check if url has #demo in it and open modal
	if (window.location.hash === '#demo') {
		modal.showModal();
	}

	modalClose.addEventListener('click', () => {
		modal.close();
	});

	const buttons = document.querySelectorAll('.c-button');
	buttons.forEach(button => {
		button.addEventListener('click', (e) => {
			if (button.href.includes('#demo')) {
				e.preventDefault();
				modal.showModal();
			}
		});
	});

	const openDemoButtons = document.querySelectorAll('.js-open-demo');
	openDemoButtons.forEach(button => {
		button.addEventListener('click', (ev) => {
			ev.preventDefault();
			modal.showModal();
		});
	});
}