export default function languageSwitcher() {
	let langToggler = document.querySelectorAll('.lang-switcher__top');

	if(!langToggler) return;

	langToggler.forEach((toggler) => {
		toggler.addEventListener('click', (e) => {
			e.preventDefault();
			toggler.parentNode.classList.toggle('lang-switcher--active');
		});
	});
}
