// import swiper and Pagination module
import Swiper from 'swiper';
import { Scrollbar, FreeMode } from 'swiper/modules';
// import  only swiper pagination styles
import 'swiper/swiper-bundle.css';
// eslint-disable-next-line
import 'swiper/css/scrollbar';

export default function pricing() {
	const pricing = document.querySelector('.pricing');

	if (!pricing) return;

	const pricingItems = pricing.querySelectorAll('.pricing__item');

	let slideUp = (target, duration = 500) => {
		target.style.transitionProperty = 'height, margin, padding';
		target.style.transitionDuration = duration + 'ms';
		target.style.boxSizing = 'border-box';
		target.style.height = target.offsetHeight + 'px';
		target.offsetHeight;
		target.style.overflow = 'hidden';
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;

		window.setTimeout(() => {
			target.style.display = 'none';
			target.style.removeProperty('height');
			target.style.removeProperty('padding-top');
			target.style.removeProperty('padding-bottom');
			target.style.removeProperty('margin-top');
			target.style.removeProperty('margin-bottom');
			target.style.removeProperty('overflow');
			target.style.removeProperty('transition-duration');
			target.style.removeProperty('transition-property');
		}, duration);
	};

	/* SLIDE DOWN */

	let slideDown = (target, duration = 500) => {
		target.style.removeProperty('display');
		let display = window.getComputedStyle(target).display;
		if (display === 'none') display = 'flex';
		target.style.display = display;
		let height = target.offsetHeight;
		target.style.overflow = 'hidden';
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
		target.offsetHeight;
		target.style.boxSizing = 'border-box';
		target.style.transitionProperty = 'height, margin, padding';
		target.style.transitionDuration = duration + 'ms';
		target.style.height = height + 'px';
		target.style.removeProperty('padding-top');
		target.style.removeProperty('padding-bottom');
		target.style.removeProperty('margin-top');
		target.style.removeProperty('margin-bottom');

		window.setTimeout(() => {
			target.style.removeProperty('height');
			target.style.removeProperty('overflow');
			target.style.removeProperty('transition-duration');
			target.style.removeProperty('transition-property');
		}, duration);
	};

	/* TOGGLE */

	var slideToggle = (target, duration = 500) => {
		if (window.getComputedStyle(target).display === 'none') {
			return slideDown(target, duration);
		} else {
			return slideUp(target, duration);
		}
	};

	if (window.innerWidth > 1023) {
		pricingItems.forEach((item) => {
			item.addEventListener('click', () => {
				const currentActiveItem = document.querySelector('.pricing__item--active');

				// Remove the class and after 300ms add the class to the clicked item
				item.classList.add('pricing__item--active');
				setTimeout(() => {
					currentActiveItem.classList.remove('pricing__item--active');
				}, 300);
			});
		});
	}

	const openListItems = document.querySelectorAll('.js-open-list');

	if (!openListItems) return;

	openListItems.forEach((item) => {
		item.addEventListener('click', () => {
			const mainParent = item.closest('.pricing__item');
			const activeItem = document.querySelector('.pricing__item--active');
			const activeList = activeItem.querySelector('.pricing__item-list');
			const parent = item.closest('.pricing__item-header');

			slideToggle(activeList);
			slideToggle(parent.nextElementSibling);

			activeItem.classList.remove('pricing__item--active');
			mainParent.classList.add('pricing__item--active');
		});
	});

	const swiper = new Swiper('.pricing__other-list', {
		slidesPerView: "auto",
		spaceBetween: 8,
		modules: [Scrollbar, FreeMode],
		freeMode: {
			enabled: true,
			sticky: true,
		},
		grabCursor: true,
		scrollbar: {
			el: '.swiper-scrollbar',
			hide: false,
		},
	});
}
